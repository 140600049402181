import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Main from './components/Main';

const App = () => {
  const theme = createTheme({
    palette: {
      primary: { main: '#2e2e2e' },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='/*' element={<Main />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
