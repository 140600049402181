const photosArray = [
    {
        name: 'Queen of the seas',
        // src: 'https://drscdn.500px.org/photo/74281761/q%3D80_m%3D2000/v2?sig=a89c551f68739060fa33f476a6e0d5953e45145caedbbb7098dde396fe05d876',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898708/029queen_of_the_seas_szyiz7.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/16.jpg`
    },
    {
        name: 'Beach Sunset',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1636815874/beach_sbgtkz.jpg',
        lazysrc: ``
    },
    {
        name: 'Resting builder',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1645965294/spider_gdbrgh.jpg',
        lazysrc: ``
    },
    {
        name: 'Mont Saint Michel',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1636815797/mont_st_michel_mr2cpq.jpg',
        lazysrc: ``
    },
    {
        name: 'I am sexy and I know it',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1636815838/CameleonSmall_e453h1.jpg',
        lazysrc: ``
    },
    {
        name: 'Flying dad',
        // src:'https://drscdn.500px.org/photo/1008468564/q%3D80_m%3D2000_k%3D1/v2?sig=ef806ce63e5556db7c01979718f96e8c3a30e19c07aadd948d63a12929b75e48',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898723/044flying_dad_m9ek7u.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/1.jpg`
    },
    {
        name: 'What are you looking at?',
        // src: 'https://drscdn.500px.org/photo/1008444553/q%3D80_m%3D2000_k%3D1/v2?sig=dd506a3c2bc0a132846969f4ec77198792adc80612ca117a0054eea3ef6e7c0d',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898722/040what_are_you_looking_at_nohj6w.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/2.jpg`
    },
    {
        name: 'Tarantula',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1646588933/tarantula_unb03k.jpg',
        lazysrc: ``
    },
    {
        name: 'Golden sky',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1646589086/Goldensky_gratqv.jpg',
        lazysrc: ``
    },
    {
        name: 'Lion portrait',
        // src: 'https://drscdn.500px.org/photo/1008331023/q%3D80_m%3D2000_k%3D1/v2?sig=05b299c0af977bf98cddc03d0ace67a6f11a19d84a6edec8a3418571ea19ad34',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898719/042lion_portrait_fqdgwu.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/3.jpg`
    },
    {
        name: 'Mouthless Butterfly',
        // src: 'https://drscdn.500px.org/photo/135945433/q%3D80_m%3D2000/v2?sig=fa9ae06dc44cce182359accc9da384d47e5f4306791167a28c4cdd06d2b01f79',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898715/041mouthless_butterfly_lr7ntl.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/4.jpg`
    },
    {
        name: 'What are you looking at?',
        // src: 'https://drscdn.500px.org/photo/135939989/q%3D80_m%3D2000/v2?sig=3f7f26d8389c3b6549290815a32e488a2e4be06d06164c219c2679686cfb088a',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898714/043what_are_you_looking_at_umx4p9.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/5.jpg`
    },
    {
        name: 'Monkey Relax',
        // src: 'https://drscdn.500px.org/photo/135413765/q%3D80_m%3D2000/v2?sig=8baba5af2905a6ccb7648d37cd1d39d4f30f3f86c7f1c7234f2db5c473bba20d',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898711/039monkey_relax_bpkctl.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/6.jpg`
    },
    {
        name: 'Break dancer',
        // src: 'https://drscdn.500px.org/photo/79818925/q%3D80_m%3D2000/v2?sig=6fcacfb2e4fabf894df0c8407372bead2ab711a187fa4fb1522eeac5402241c1',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898709/038break_dancer_itgpza.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/7.jpg`
    },
    {
        name: 'Franck',
        // src: 'https://drscdn.500px.org/photo/79582201/q%3D80_m%3D1000/v2?sig=25fa42aa25b42dbede7543d0335f8e37ef741aea05f2b9a55fce7f59e82b7882',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898708/037franck_usixt4.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/8.jpg`
    },
    {
        name: 'Burning the midnight oil',
        // src: 'https://drscdn.500px.org/photo/79063913/q%3D80_m%3D2000/v2?sig=a351a02e1f8bf369eba4c474aae0995dfade1ceedcf709903f437fbe35ed004c',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898708/036burning_the_midnight_oil_iat4ex.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/9.jpg`
    },
    {
        name: 'Modern nature',
        // src: 'https://drscdn.500px.org/photo/78293811/q%3D80_m%3D1000/v2?sig=93e3ecf01040b068e28f9405dee93c8d6b26ce849ced44bb3a8d87dff99e66a4',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898705/035modern_nature_orwk7j.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/10.jpg`
    },
    {
        name: 'Paris on fire',
        // src: 'https://drscdn.500px.org/photo/77018395/q%3D80_m%3D1000/v2?sig=7fce5f803100e437be7750dfe9fd3becbae2c2ad8a22b58b3638753837643e34',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898706/034paris_on_fire_hiazzu.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/11.jpg`
    },
    {
        name: 'Ghost Tower',
        // src: 'https://drscdn.500px.org/photo/76620805/q%3D80_m%3D1000/v2?sig=34602e05c206b8be451ae85f876c84edd3b57df765f576969593964a1a68c85b',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898703/033ghost_tower_r7mhcp.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/12.jpg`
    },
    {
        name: 'Rush landscape',
        // src: 'https://drscdn.500px.org/photo/76431305/q%3D80_m%3D2000/v2?sig=e2a0abac754d073818f857d1b9996e1ad9cc2ea50ad17219987a721d42bb0691',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898703/032rush_landscape_q6skaj.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/13.jpg`
    },
    {
        name: 'Sunrise',
        // src: 'https://drscdn.500px.org/photo/76237087/q%3D80_m%3D2000/v2?sig=cb1652b260738eb63c2de6093071abf5e46d86744373889515bbbf5b3709ecfa',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898700/031sunrise_nvtr8b.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/14.jpg`
    },
    {
        name: 'Little Mermaid',
        // src: 'https://drscdn.500px.org/photo/74489931/q%3D80_m%3D2000/v2?sig=9b9dbdc403a3cccfed284a81736719ff348640223a79ec938e70f33e3bee9818',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898698/030little_mermaid_c2rqka.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/15.jpg`
    },
    {
        name: 'Butterfly',
        // src: 'https://drscdn.500px.org/photo/70005509/q%3D80_m%3D2000/v2?sig=6d4ce8ae77927ca279b0615d0a47201ec3872ad01b3ded5febde640f3fb3a93c',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898703/026butterfly_nvw1bk.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/17.jpg`
    },
    {
        name: 'Polar Bear',
        src: 'https://drscdn.500px.org/photo/69252975/q%3D80_m%3D1000/v2?sig=3217580372c49e501b84d4e17a50bd6523757d9f3ce5dd1049e13c1dd644aab4',
        lazysrc: `${process.env.PUBLIC_URL}/photos/18.jpg`
    },
    {
        name: 'Papillon',
        // src: 'https://drscdn.500px.org/photo/69103603/q%3D80_m%3D2000/v2?sig=8e94be534f18ded168a8b104c21bb795b01a347dd572170774e41c0ace1e6662',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898697/028butterfly_ubbkvi.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/19.jpg`
    },
    {
        name: 'White Moustache',
        // src: 'https://drscdn.500px.org/photo/68786997/q%3D80_m%3D2000/v2?sig=50fbb6656d2ffb5e20363b429ff71dce2de61706ff7faa4431cbefc39bb7ccd8',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898685/024white_moustache_txo6em.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/21.jpg`
    },
    {
        name: 'Bird',
        // src: 'https://drscdn.500px.org/photo/68618633/q%3D80_m%3D2000/v2?sig=1bf41745a4cdb36330debec8cfdcccb4f127ce2a902364b7ceedb070ceaa895f',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898684/023bird_mqtrwy.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/22.jpg`
    },
    {
        name: 'Europe',
        // src: 'https://drscdn.500px.org/photo/68275005/q%3D80_m%3D2000/v2?sig=a8f58011c77d7f65e79545832d71d367f51af768938f5537d211c3701427ffdd',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898702/022europe_qdmcxh.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/23.jpg`
    },
    {
        name: 'Levitation',
        // src: 'https://drscdn.500px.org/photo/67964245/q%3D80_m%3D2000/v2?sig=cc41cf8c6eafccfcfa2c705a837f9b3ea745cd386b877ba867b8b31e5d529c63',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898692/021levitation_h67ncq.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/24.jpg`
    },
    {
        name: 'Ghost',
        // src: 'https://drscdn.500px.org/photo/67816495/q%3D80_m%3D2000/v2?sig=b1d46ff69be0f2f0589dd4bf9ea50fa38e63e627b54764b6bf10d248ab647cc8',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898679/020ghost_a7qzu7.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/25.jpg`
    },
    {
        name: 'Poseidon',
        // src: 'https://drscdn.500px.org/photo/66864195/q%3D80_m%3D2000/v2?sig=8715caa02d405a77973209046a0fcdb05e150a18c419ea2e47fefd865a189e69',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898681/019poseidon_znabvd.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/26.jpg`
    },
    {
        name: 'Watch me',
        // src: 'https://drscdn.500px.org/photo/66669321/q%3D80_m%3D2000/v2?sig=1b4e53f159b5a5abe2ce9d4b45ca73379f94c7cc6d6b825f22622d70c83e631d',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898678/018watch_me_hqvwfx.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/27.jpg`
    },
    {
        name: 'Castle',
        // src: 'https://drscdn.500px.org/photo/66034941/q%3D80_m%3D1000/v2?sig=40b084394b23e152da7f1f99a15f700f9be7852ba3cdd1304acaf92d9d62ab0d',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898674/017castle_naiops.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/28.jpg`
    },
    {
        name: 'Harbor',
        // src: 'https://drscdn.500px.org/photo/65949637/q%3D80_m%3D1000/v2?sig=914e1c652e0db8c1e93e239be180d5eb4819efd06548c2ab90d9d66752636818',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898674/016harbor_wdoubg.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/29.jpg`
    },
    {
        name: 'Little House',
        // src: 'https://drscdn.500px.org/photo/65949635/q%3D80_m%3D2000/v2?sig=dc7cf6fd48db9157aaa70197dc5b8399053368c53eb533d4a10cc8c8c041dd1f',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898693/015little_house_z20cu9.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/30.jpg`
    },
    {
        name: 'Spring is starting at the Church',
        // src: 'https://drscdn.500px.org/photo/65949631/q%3D80_m%3D1000/v2?sig=e9ee78c3a981889e20887ef2cbf926cfb4e79f17426b66fcd535f4378b643268',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898668/014spring_at_the_church_szacgc.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/31.jpg`
    },
    {
        name: 'Entrance',
        // src: 'https://drscdn.500px.org/photo/65949629/q%3D80_m%3D2000/v2?sig=e25c171f999586085f2e54a7f94e8b9d817c53daf3c73e7c75ddac42808332cd',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898671/013entrance_xhwo7d.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/32.jpg`
    },
    {
        name: 'Boat',
        // src: 'https://drscdn.500px.org/photo/65442963/q%3D80_m%3D1000/v2?sig=164756f009667ad763713e803d58be2da6a4b90bb25010fa0e21dacd22bb2c24',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898665/012boat_alexwq.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/33.jpg`
    },
    {
        name: 'Castle',
        // src: 'https://drscdn.500px.org/photo/65403737/q%3D80_m%3D2000/v2?sig=5b47733907b57ca7e43b6efc60a5458ad64bd903b280a3beb4aae6b7a989ccff',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898674/011castle_pdtf90.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/34.jpg`
    },
    {
        name: 'Eiffel Tower',
        // src: 'https://drscdn.500px.org/photo/65403735/q%3D80_m%3D1000/v2?sig=caee5cd03af1637bb4786ced7b628c161408b53a6d2b49f49610c6ec601b9616',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898663/010eiffel_tower_yaihz5.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/35.jpg`
    },
    {
        name: 'Soldiers',
        // src: 'https://drscdn.500px.org/photo/65197567/q%3D80_m%3D1000/v2?sig=5e99e9a0fb10699ba9263d1e1fdeb4a877a0920025b33d0fd135d8cd14bf523c',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898663/009soldiers_a4xsw4.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/36.jpg`
    },
    {
        name: 'Marching',
        // src: 'https://drscdn.500px.org/photo/65197563/q%3D80_m%3D2000/v2?sig=18e7c06ee485cbd6a635becb5e4de8b3792965f81ca9cb62602e9d3445578cc9',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898679/008marching_of5bup.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/37.jpg`
    },
    {
        name: 'Boat',
        // src: 'https://drscdn.500px.org/photo/65197561/q%3D80_h%3D300/v2?sig=a69a073b267535dbcdca75f906505ac61566a024a2b962749f0a546b020c5569',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898659/007boat_uyf1ca.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/38.jpg`
    },
    {
        name: 'Standard',
        // src: 'https://drscdn.500px.org/photo/64894181/q%3D80_m%3D2000/v2?sig=f2b43e72d979bd5b6559b8792146c4f20b74cbc07df2715b29911e77460d11de',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898662/006standards_rq800t.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/39.jpg`
    },
    {
        name: 'Foot steps',
        // src: 'https://drscdn.500px.org/photo/64797121/q%3D80_m%3D1000/v2?sig=5bb3c57db2d2d2b8cd8cd932c8d8fca34686fdc630d5b2f353c29844e75da0f6',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898660/005foot_steps_c8v6lk.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/40.jpg`
    },
    {
        name: 'Roller Coaster',
        // src: 'https://drscdn.500px.org/photo/64793583/q%3D80_m%3D2000/v2?sig=286db587087d78f9e646b3281ed0038b5f5a009ca0a5aa85bc5ca4c60cc74904',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898660/004roller_coaster_cphbo9.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/41.jpg`
    },
    {
        name: 'Tree sunset',
        // src: 'https://drscdn.500px.org/photo/64691091/q%3D80_m%3D1000/v2?sig=980865a392595ea1d3431f316653aa77a832ae4809153899a94f8fe943cf80f3',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898660/003tree_sunset_mlulpa.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/42.jpg`
    },
    {
        name: 'Tree of Life',
        // src: 'https://drscdn.500px.org/photo/60625754/q%3D80_m%3D1000/v2?sig=9b8cbdf178e8673cf83f7836a6064b0f7a504c1ded438a7eb40d9f9f8d9f99e5',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1579898658/001tree_of_life_qiv2wu.jpg',
        lazysrc: `${process.env.PUBLIC_URL}/photos/44.jpg`
    },
    {
        name: 'Confrontation',
        src: 'https://res.cloudinary.com/angeloron/image/upload/v1636815796/cat_s4vz61.jpg',
        lazysrc: ``
    },
]

export default photosArray;