import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import CustomLink from '../CustomLink.js';
import { menu_array } from "./MenuArray";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './css/Menu.css';

const Menu = ({ toggleDrawer }) => {
    const [active, setActive] = useState('PHOTOS');

    const location = useLocation();

    useEffect(() => {
        const currentPage = location.pathname.replace('/', '').toUpperCase() || 'HOME';
        setActive(currentPage);
    }, [location]);

    return (
        <div id="menu">
            {menu_array.map((item, index) => (
                <CustomLink key={index} to={`/${item.name === "HOME" ? "" : item.name.toLowerCase()}`}>
                    <div
                        className={`menu_item${active === item.name.toUpperCase() ? ' active' : ''}`}
                        onClick={() => setActive(item.name.toUpperCase())}
                    >
                        {item.name}
                    </div>
                </CustomLink>
            ))}
            <div id="mobileMenuToggler">
                <h1>Ange Loron</h1>
                <div>
                    <IconButton onClick={() => toggleDrawer(true)} aria-label="menu" size="small">
                        <MenuIcon fontSize="large" />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default Menu;
